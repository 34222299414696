<template>
  <b-overlay
    :show="spinner"
    spinner-variant="primary"
  >
    <b-card>
      <usuariosForm
        :usuario="usuarioData"
        usuarioSubmit="Crear Usuario"
        @processUsuario="agregarUsuario"
      />
    </b-card>
  </b-overlay>
</template>

<script>

import { BCard, BOverlay } from 'bootstrap-vue'
import { mapActions } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const usuariosForm = () => import('@/layouts/components/Usuarios/UsuariosForm.vue')

export default {
  components: {
    BCard,
    BOverlay,
    usuariosForm,
  },
  data() {
    return {
      spinner: false,
      usuarioData: {
        nombre: '',
        telefono: '',
        telefonoInterno: '',
        correo: null,
        password: null,
        imagen: null,
        estado: null,
        tipoUsuario: null,
        permissions: [],
      },
    }
  },
  methods: {
    ...mapActions({ createUsuario: 'usuarios/addUsuarios' }),
    agregarUsuario(usuario) {
      this.spinner = true
      this.createUsuario(usuario).then(() => {
        const errorUsuarios = store.state.usuarios
        const errorMessage = errorUsuarios.errorMessage.errors
        if (!errorUsuarios.error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Usuario creado con éxito 👍',
              icon: 'CheckIcon',
              text: `El usuario "${usuario.nombre}" fue creado con éxito!`,
              variant: 'success',
            },
          },
          {
            timeout: 4000,
          })
          this.$router.replace({
            name: 'usuarios',
          })
        } else if (errorMessage.correo) {
          this.$swal({
            title: 'Error!',
            text: `${errorMessage.correo[0]}!`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        } else {
          this.$swal({
            title: 'Error!',
            text: 'Ingreso de datos fraudulento!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
        this.spinner = false
      })
    },
  },
}
</script>
